export const projects = [
  {
    title: 'Rauzy Bot',
    description: 'Twitter bot that displays a new Rauzy fractal everyday.',
    tech: ['Python', 'NumPy', 'Matplotlib', 'Twitter API'],
    hrefPage: 'https://twitter.com/graphtrib/',
    hrefCode: 'https://github.com/polygonart6777/Finite-graph-fractal-sets',
  },
  {
    title: 'Rauzy page',
    description: 'Interactive page to display a Rauzy fractal.',
    tech: ['Javascript', 'Plotly'],
    hrefPage: 'https://polygonart6777.github.io/RauzyPage',
    hrefCode: 'https://github.com/polygonart6777/RauzyPage',
  },
  {
    title: 'Lin alg textbook',
    description: 'Open source introductory linear algebra textbook with interactive problems and solutions.',
    tech: ['XML', 'PreText'],
    hrefPage: 'https://www.math.uvic.ca/~eaglec/Math110/acknowledgement-1.html',
    hrefCode: 'https://github.com/eaglecj/MATH110OER',
  },
  {
    title: '3d printed solenoid',
    description:
      'The famous dynamical system, 2-adic solenoid, printed in Nickel. Featured in Illustrating Mathematics.',
    tech: ['Mathematica', 'Shapeways'],
    hrefPage: 'https://www.shapeways.com/designer/polygonart6777/creations',
    hrefCode: '',
  },
  {
    title: 'String Art',
    description: 'Straight lines make an envelope of a parbola animated using pure Javascript.',
    tech: ['Javascript'],
    hrefPage: 'https://www.math.uvic.ca/~buricd/stringart.html',
    hrefCode: 'https://github.com/polygonart6777/String-Art-Webpage',
  },
  {
    title: 'Aperiodic coasters',
    description: 'Laster cut coasters of some famour aperiodic tilings.',
    tech: ['Mathematica', 'Inkscape'],
    hrefPage: 'Info',
    hrefCode: '',
  },
];

//How did you build it?
//What problems were you trying to solve?
//What were some of the design decisions?
//What packages did you use to solve the decisions?
//What did you learn?
//What would you do differently next time?
